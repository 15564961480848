@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;600;700&family=Dancing+Script:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Cormorant Garamond';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
